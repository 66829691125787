import { useRef, useEffect } from "react"
import { useSpring, interpolate } from "react-spring"
import { useGesture } from "react-use-gesture"
import useIsTouch from "./useIsTouch"

export default function useAutoCarousel(nodeRef) {
  let isTouch = useIsTouch()
  // Prevent link click while dragging
  let dragging = useRef(false)
  useEffect(() => {
    let handler = event => {
      if (dragging.current && nodeRef.current.contains(event.target)) {
        event.preventDefault()
      }
    }
    document.body.addEventListener("click", handler, false)
    return () => {
      document.body.removeEventListener("click", handler, false)
    }
  }, [nodeRef])

  // Prevent vertical scroll if swiping horizontally.
  // useEffect(() => {
  //   let node = nodeRef.current
  //   let handler = event => {
  //     if (dragging.current && event.cancelable) {
  //       event.preventDefault()
  //       event.returnValue = false
  //       return false
  //     }
  //   }
  //   if (node != null) {
  //     node.addEventListener("touchmove", handler, { passive: false })
  //   }
  //   return () => {
  //     node.removeEventListener("touchmove", handler, {
  //       passive: false,
  //     })
  //   }
  // }, [nodeRef])

  let offsetSpringConfig = {
    mass: 1,
    tension: 1000,
    friction: 120,
  }
  let [{ offset }, setOffset] = useSpring(() => ({
    offset: 0,
    config: offsetSpringConfig,
  }))

  let [{ momentum }, setMomentum] = useSpring(() => ({
    momentum: 1,
    config: {
      mass: 4,
      tension: 40,
      friction: 10,
    },
  }))
  let [autoOffset, setAutoOffset] = useSpring(() => ({
    offset: 0,
    config: {
      immediate: true,
    },
  }))

  useEffect(() => {
    let frameId = null
    if (!isTouch) {
      let frame = () => {
        let m = momentum.getValue()
        if (m > 0.01) {
          setAutoOffset({ offset: autoOffset.offset.getValue() - m * 10 })
        }
        frameId = requestAnimationFrame(frame)
      }
      frame()
    }

    return () => {
      cancelAnimationFrame(frameId)
    }
  }, [isTouch, momentum, autoOffset, setAutoOffset])

  let bindGestures = useGesture(
    {
      onDrag: ({
        velocity,
        direction,
        first,
        last,
        movement,
        memo = offset.getValue(),
      }) => {
        if (first) {
          dragging.current = true
        }
        if (last) {
          setOffset({
            config: {
              ...offsetSpringConfig,
              velocity: direction.map(d => d * velocity * 0.3),
              decay: true,
            },
          })
          setTimeout(() => {
            dragging.current = false
          }, 100)
        } else {
          setOffset({ offset: movement[0] + memo, config: offsetSpringConfig })
        }
        return memo
      },
      onHover: ({ hovering }) => {
        if (hovering) {
          setMomentum({ momentum: 0 })
        } else {
          setMomentum({ momentum: 1 })
        }
      },
    },
    { domTarget: nodeRef, dragDelay: true }
  )
  useEffect(bindGestures, [bindGestures])

  return interpolate([offset, autoOffset.offset], (a, b) => a + b)
}
