let pageY = 0
let watchers = []

if (typeof window !== "undefined") {
  window.addEventListener(
    "scroll",
    event => {
      pageY = (
        document.documentElement ||
        document.body.parentNode ||
        document.body
      ).scrollTop
      watchers.forEach(fn => {
        fn(pageY)
      })
    },
    { capture: false, passive: true }
  )
}

export const watch = fn => {
  fn(pageY)
  watchers.push(fn)
}

export const unwatch = fn => {
  watchers = watchers.filter(f => f !== fn)
}

export const now = () => {
  return pageY
}

export const getMax = () => {
  return typeof window !== "undefined"
    ? (document.documentElement || document.body.parentNode || document.body)
        .scrollHeight - window.innerHeight
    : 0
}
