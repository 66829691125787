import React from "react"

// TODO: refactor this hook to useViewportState for the scroll position

let scrollUpThreshold = 140
let distFromTopThreshold = 300

export default function useShyHeader() {
  let [hideHeader, setHideHeader] = React.useState(false)

  let direction = React.useRef(0)
  let lastScrollTop = React.useRef(0)
  let lastDirectionChangeScrollTop = React.useRef(0)

  React.useEffect(() => {
    let intervalId = window.setInterval(() => {
      let scrollNow = Math.max(
        0,
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop
      )
      let directionNow =
        Math.sign(scrollNow - lastScrollTop.current) || direction.current
      if (direction.current !== directionNow) {
        lastDirectionChangeScrollTop.current = scrollNow
      }

      // If scrolling down and past the `distFromTopThreshold` value:
      if (directionNow === 1 && scrollNow >= distFromTopThreshold) {
        setHideHeader(true)
      } else if (
        directionNow === -1 &&
        (lastDirectionChangeScrollTop.current - scrollNow >=
          scrollUpThreshold ||
          scrollNow <= scrollUpThreshold)
      ) {
        setHideHeader(false)
      }
      direction.current = directionNow
      lastScrollTop.current = scrollNow
    }, 200)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [])
  return hideHeader
}
