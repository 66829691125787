// All entries should pass in the response from this query as props.seo to the
// Layout component.
//
// Right now, these four values are requied.
//
// - metaTagContainer
// - metaLinkContainer
// - metaTitleContainer,
// - metaJsonLdContainer
//

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import ReactHtmlParser from "react-html-parser"

const Seo = props => {
  // If props.seo doesn't exist, we'll use the homepage data as a fallback
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          seo: seomatic(uri: "/") {
            metaTagContainer
            metaLinkContainer
            metaTitleContainer
            metaJsonLdContainer
          }
        }
      }
    `
  )

  if (!props.data) {
    console.warn(
      "Warning: No SEO data provided. Using Homepage data as a fallback."
    )
  }

  const fallbackSeo = data.craft.seo
  const seo = props.data ? props.data : fallbackSeo
  const titleTag = seo.metaTitleContainer
  const metaTags = seo.metaTagContainer
  const linkTags = seo.metaLinkContainer
  const jsonLdScript = seo.metaJsonLdContainer
  // ReactHtmlParser doesn't allow <script> elements so we need to rebuild them
  // inside the <Helmet /> component
  // https://gist.github.com/rjgux/1a7ec4993c8e0a18b51322c9a8c6eed9
  const splitJsonLdStringIntoArray = jsonLdScript
    .replace(/<\/script>/g, "")
    .split(`<script type="application/ld+json">`)

  // Sketchy way of logging social media images for debugging purposes
  // console.log(
  //   `https://zj-v6.imgix.net${
  //     metaTags
  //       .split('<meta content="https://zj-v6.imgix.net')[1]
  //       .split(".jpg")[0]
  //   }.jpg`
  // )

  return (
    <Helmet defer={false} htmlAttributes={{ lang: "en" }}>
      <link rel="shortcut icon" sizes="16x16 32x32 64x64" href="/favicon.ico" />
      {ReactHtmlParser([titleTag, metaTags, linkTags])}
      {splitJsonLdStringIntoArray.map((script, index) => (
        <script type="application/ld+json" key={`json-ld-${index}`}>
          {script}
        </script>
      ))}
      {/*
        Remove MS Edge phone number styling
        https://stackoverflow.com/questions/31978346/remove-microsoft-edges-phone-number-styling
      */}
      <meta name="format-detection" content="telephone=no" />
    </Helmet>
  )
}

export default Seo
