import React, { useReducer } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import InputText from "./inputText"
import styles from "./newsletterSignup.module.scss"
import submitHubSpotForm from "../services/submitHubSpotForm"

const formId = "08dd51e6-5228-4548-8c3c-d6ba8fd6b54e"

const NewsletterSignup = props => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "input":
          return {
            ...state,
            email: action.value,
          }
        case "submitted":
          return {
            ...state,
            submitting: true,
            errorMessage: null,
            successMessage: null,
          }
        case "succeeded":
          return {
            ...state,
            submitting: false,
            successMessage: action.message || "Success!",
          }
        case "failed":
          return {
            ...state,
            submitting: false,
            errorMessage: action.message || "Error. Try again later.",
          }
        default:
          return state
      }
    },
    {
      submitting: false,
      errorMessage: null,
      successMessage: null,
      email: "",
    }
  )

  const handleSubmit = event => {
    event.preventDefault()
    dispatch({ type: "submitted" })

    submitHubSpotForm(formId, [
      {
        name: "email",
        value: state.email,
      },
    ])
      .then(data => {
        // Track custom Google Analytics events
        trackCustomEvent({
          category: "Newsletter Signup",
          action: "Form Submitted",
          hitCallback: () => {
            dispatch({ type: "succeeded" })
          },
        })
      })
      .catch(error => {
        const errorType = error.response.data.errors[0].errorType
        // console.log("error: ", error.response.data)
        if (errorType === "INVALID_EMAIL") {
          dispatch({
            type: "failed",
            message:
              "That doesn’t look quite right. We'll need a valid email address.",
          })
        } else {
          dispatch({
            type: "failed",
            message:
              "Something went wrong. Sorry about that. Try again later or send us a message hello@zeusjones.com",
          })
        }
      })
  }

  return (
    <form action="#" method="post" onSubmit={handleSubmit}>
      <div className={styles.miniForm}>
        <div className={styles.miniFormInput}>
          <InputText
            label="Email Address"
            name="cm-kjttv-kjttv"
            id={props.id ? props.id : "footerNewsletter2"}
            type="email"
            placeholder=""
            required={true}
            block={true}
            onChange={value => {
              dispatch({ type: "input", value })
            }}
            value={state.email}
          />
        </div>
        <div className={styles.miniFormButton}>
          <button
            className="button button--small button--block"
            type="submit"
            disabled={state.submitting}
          >
            Sign up
          </button>
        </div>
        <div className={styles.miniFormResponse}>
          <div className="error">{state.errorMessage}</div>
          <div className={styles.miniFormResponseSuccess}>
            {state.successMessage}
          </div>
        </div>
      </div>
    </form>
  )
}

export default NewsletterSignup
