import axios from "axios"

const ipFunction = "/.netlify/functions/ip"

export default (formId, fields) => {
  const portalId = "5485317"
  const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`
  const hutk =
    typeof document !== "undefined"
      ? document.cookie.replace(
          /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
          "$1"
        )
      : null
  const ctx = {
    pageUri: typeof window !== "undefined" ? window.location.href : "",
  }
  if (hutk) {
    ctx.hutk = hutk
  }
  console.log(postUrl, hutk, ctx, fields)

  const hubspotData = {
    submittedAt: Date.now(),
    fields: fields,
    context: ctx,
  }

  return axios.post(ipFunction).then(data => {
    // Request IP address & add to context
    console.log(data)
    if (data.data) {
      hubspotData.context.ipAddress = data.data
    }
    return axios.post(postUrl, JSON.stringify(hubspotData), {
      headers: {
        "Content-type": "application/json",
      },
    })
  })
}
