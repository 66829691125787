import React from "react"

import styles from "./inputText.module.scss"

const Input = props => {
  let [state, dispatch] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case "focused":
          return { ...state, focused: true }
        case "blurred":
          return {
            ...state,
            focused: false,
            value: state.value.trim() !== "" ? state.value : "",
          }
        case "changed":
          return { ...state, value: action.value }
        default:
          return state
      }
    },
    { focused: false, value: props.value != null ? props.value : "" }
  )

  // FIXME: This component owns an internal controlled input, but is used as
  // both a controlled and uncontrolled input in different contexts. It's
  // working for now, but it's a bit convoluted.
  let value = props.value != null ? props.value : state.value

  let isFloating = state.focused || value.trim() !== ""

  return (
    <div className={styles.wrapper}>
      <div
        className={
          styles.floatLabel + (isFloating ? ` ${styles.isFloating}` : "")
        }
      >
        <label className={styles.floatLabelLabel} htmlFor={props.id}>
          {props.label}
        </label>
        <input
          onFocus={() => {
            dispatch({ type: "focused" })
          }}
          onBlur={() => {
            dispatch({ type: "blurred" })
          }}
          onChange={event => {
            let value = event.target.value
            dispatch({ type: "changed", value })
            if (props.onChange != null) {
              props.onChange(value)
            }
          }}
          value={value}
          id={props.id}
          className={
            styles.floatLabelInput +
            (props.block ? ` ${styles.floatLabelInputBlock}` : "")
          }
          name={props.name}
          placeholder={props.placeholder}
          type={props.type}
          required={props.required ? props.required : undefined}
          // Pass additional input html attributes.
          // Ex. password.js autocorrect, spellcheck, etc. attributes
          {...props.htmlAttributes}
        />
      </div>
    </div>
  )
}

export default Input
