import React from "react"
import createStore from "zustand"
import shallow from "zustand/shallow"

import * as Scroll from "../scroll"

export const [useStore, api] = createStore(set => ({
  scrollTop: Scroll.now(),
  maxScroll: Scroll.getMax(),
  width: 0,
  height: 0,
  mouseX: typeof window !== "undefined" ? window.innerWidth / 2 : 0,
  mouseY: typeof window !== "undefined" ? window.innerHeight / 2 : 0,
}))

Scroll.watch(scrollTop => {
  api.setState({ scrollTop, maxScroll: Scroll.getMax() })
})

function updatePageSizes() {
  api.setState({
    maxScroll: Scroll.getMax(),
    width: window.innerWidth,
    height: window.innerHeight,
  })
}

if (typeof window !== "undefined") {
  window.onInitialClientRender = updatePageSizes

  let resizeTimeoutId = null
  window.addEventListener(
    "resize",
    function(event) {
      window.clearTimeout(resizeTimeoutId)
      resizeTimeoutId = window.setTimeout(updatePageSizes, 100)
    },
    { capture: false, passive: true }
  )

  window.addEventListener(
    "mousemove",
    function(event) {
      api.setState({
        mouseX: event.pageX,
        mouseY: event.pageY,
      })
    },
    { capture: false, passive: true }
  )
}

export default function useViewportState(callback, selector = x => x) {
  React.useEffect(() => api.subscribe(callback, selector, shallow), [
    callback,
    selector,
  ])
}
