/*
  USAGE:

  Normal:
    This will lazy-load the image with the Low Quality Image Placeholder
    Technique (LQIP) and will maintain the image's natural aspect ratio.

    <Image
      width={smallImage.width}
      height={smallImage.height}
      url={smallImage.url}
      alt={smallImage.altText}
    />

  Cropped:
    To crop an image you'll still pass all of the above in addition to crop={true}
    aspectRatio="w:h", and focalPoint={asset.focalPoint}

  <Image
    height={portrait.height}
    width={portrait.width}
    crop={true}
    url={portrait.url}
    aspectRatio="2:3"
    focalPoint={portrait.focalPoint}
    altText={portrait.altText}
  />

  Art-directed (different sizes/crops at different breakpoints using <picture>):

    Not supported in this component since there's currently only one instance at
    the moment and can be found in singleImage.js

 */
import React from "react"
import Imgix, { buildURL } from "react-imgix"
import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import styles from "./image.module.scss"

const Image = props => {
  const ext = props.url.substr(props.url.lastIndexOf(".") + 1)
  const isGif = ext === "gif"
  const classes = `${styles.image} ${props.className ? props.className : null}`

  // Pass through any additional HTML attributes that are added
  let additionalHtmlAttributes = props.htmlAttributes || {}

  // Parameters for cropped images (only Jones portraits at the moment)
  const croppedImgixParams = {
    auto: ["compress", "format"],
    ar: props.aspectRatio ? props.aspectRatio : null,
    fit: "crop",
    "fp-x": props.focalPoint ? props.focalPoint[0] : null,
    "fp-y": props.focalPoint ? props.focalPoint[1] : null,
  }

  return isGif ? (
    // Imgix gif compression is finnicky, just send back a regular 'ol <img />
    <img src={props.url} className={classes} alt={props.alt ? props.alt : ""} />
  ) : (
    <Imgix
      className={`lazyload ${classes}`}
      src={props.url}
      sizes={props.sizes}
      // Cropping with imgixParams isnt' compatible with a set height/width attr
      // width/height override sizes so exclude those if `sizes` is provided
      width={props.crop || props.sizes ? null : props.width}
      height={props.crop || props.sizes ? null : props.height}
      // lazysizes attributes
      attributeConfig={{
        src: "data-src",
        srcSet: "data-srcset",
        sizes: "data-sizes",
      }}
      htmlAttributes={{
        ...additionalHtmlAttributes,
        // LQIP
        src: buildURL(props.url, {
          w: 50,
          fit: "crop",
          "fp-x": props.crop ? props.focalPoint[0] : null,
          "fp-y": props.crop ? props.focalPoint[1] : null,
          ar: props.crop ? props.aspectRatio : null,
          blur: 40,
        }),
        alt: props.alt ? props.alt : "",
      }}
      imgixParams={props.crop ? { ...croppedImgixParams } : null}
    />
  )
}

export default Image
