import _useAutoCarousel from "./useAutoCarousel"
import _useIsTouch from "./useIsTouch"
import _useMedia from "./useMedia"
import _usePrevious from "./usePrevious"
import _useShyHeader from "./useShyHeader"

export const useAutoCarousel = _useAutoCarousel
export const useIsTouch = _useIsTouch
export const useMedia = _useMedia
export const usePrevious = _usePrevious
export const useShyHeader = _useShyHeader
