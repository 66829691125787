import { useStore as useViewportStore } from "./useViewportState"

// NOTE: if you edit these breakpoint values, please also update src/scss/_breakpoints.scss
let breakpoints = {
  tablet: 768,
  desktop: 1024,
  widescreen: 1216,
  fullhd: 1408,
}

function parseQuery(q) {
  let matches = q.match(/^([<=>]{0,2})([\w]+)$/)
  if (matches == null) return {}
  return {
    ops: matches[1].split(""),
    px: breakpoints[matches[2]],
  }
}

export default function useMedia() {
  let width = useViewportStore(state => state.width)

  return query => {
    let parsed = parseQuery(query)
    if (parsed.px === null || parsed.ops.length < 0 || parsed.ops.length > 2) {
      if (process.env.NODE_ENV !== "production") {
        console.error(`[useMedia] Unable to parse media query: ${query}`)
      }
      return false
    }

    return (
      parsed.ops.find(op => {
        switch (op) {
          case ">":
            return width > parsed.px
          case "<":
            return width < parsed.px
          case "=":
            return width === parsed.px
          default:
            return false
        }
      }) != null
    )
  }
}
