import React from "react"

export default function useIsTouch() {
  let initialGuess = false
  try {
    initialGuess = window.matchMedia("(hover: none)").matches
  } catch (e) {}
  let [isTouch, set] = React.useState(initialGuess)
  React.useEffect(() => {
    function handler() {
      set(true)
      window.removeEventListener("touchstart", handler, false)
    }
    window.addEventListener("touchstart", handler, false)

    return () => {
      window.removeEventListener("touchstart", handler, false)
    }
  })
  return isTouch
}
