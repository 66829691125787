import React from "react"

const Bcorps = () => {
  return (
    <>
      <svg
        className="bcorps"
        width="96.7px"
        height="163px"
        viewBox="0 0 96.7 163"
        // style="overflow: visible; enable-background: new 0 0 96.7 163"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="bcorps-logo-title"
        role="img"
        fill="currentColor"
      >
        <title id="bcorps-logo-title">Bcorps Certification Logo</title>
        <g>
          <g>
            <path
              d="M28.2,47h20c4.9,0,11.3,0,14.6,2c3.6,2.2,6.5,6.1,6.5,11.5c0,5.9-3.1,10.3-8.2,12.3V73
			c6.8,1.4,10.5,6.5,10.5,13.3c0,8.1-5.8,15.8-16.7,15.8H28.2V47z M35.6,70.7h14.7c8.3,0,11.7-3,11.7-8.8c0-7.6-5.4-8.7-11.7-8.7
			H35.6V70.7z M35.6,95.9h18.4c6.3,0,10.3-3.9,10.3-9.9c0-7.2-5.8-9.2-12-9.2H35.6V95.9z"
            />
            <path
              d="M90.3,74.6c0,23.1-18.8,41.9-41.9,41.9c-23.1,0-41.9-18.8-41.9-41.9c0-23.1,18.8-41.9,41.9-41.9
			C71.6,32.7,90.3,51.4,90.3,74.6z M48.4,26.3c-26.7,0-48.3,21.6-48.3,48.3c0,26.7,21.6,48.3,48.3,48.3c26.7,0,48.3-21.6,48.3-48.3
			C96.7,47.9,75.1,26.3,48.4,26.3z"
            />
          </g>
          <g>
            <rect x="0.3" y="133" width="96.3" height="6.3" />
            <path
              d="M93.6,126c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3
			c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0H93v1.1H93.6z M93.8,124.5
			c0.4,0,0.7,0.1,0.9,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.1-0.4,0.2-0.6,0.3l1,1.5h-0.6l-0.9-1.4H93v1.4h-0.5
			v-3.4H93.8z M91.4,127.2c0.1,0.3,0.3,0.6,0.5,0.8c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.7-0.1,0.9-0.2
			c0.3-0.1,0.5-0.3,0.8-0.5c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.6,0.2-1c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8
			c-0.2-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.7,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.5,0.8
			c-0.1,0.3-0.2,0.6-0.2,1C91.3,126.5,91.3,126.9,91.4,127.2 M91,125.1c0.2-0.3,0.4-0.6,0.6-0.9c0.3-0.3,0.6-0.5,0.9-0.6
			c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.8,0.1,1.1,0.2c0.3,0.2,0.6,0.3,0.9,0.6c0.3,0.3,0.5,0.6,0.6,0.9c0.2,0.4,0.2,0.7,0.2,1.1
			c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.4-0.4,0.7-0.6,0.9c-0.3,0.3-0.6,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.1,0.2c-0.4,0-0.8-0.1-1.1-0.2
			c-0.4-0.1-0.7-0.3-0.9-0.6c-0.3-0.3-0.5-0.6-0.6-0.9c-0.2-0.3-0.2-0.7-0.2-1.1C90.7,125.8,90.8,125.4,91,125.1"
            />
          </g>
          <g>
            <path
              d="M11.9,5.1c-0.2-0.4-0.5-0.7-0.9-1c-0.3-0.3-0.7-0.5-1.2-0.6C9.5,3.3,9,3.2,8.6,3.2C7.7,3.2,7,3.4,6.4,3.7
			C5.8,4.1,5.3,4.5,4.9,5.1C4.5,5.6,4.3,6.3,4.1,7C3.9,7.7,3.8,8.4,3.8,9.2c0,0.7,0.1,1.4,0.3,2.1c0.2,0.7,0.4,1.3,0.8,1.9
			c0.4,0.6,0.9,1,1.5,1.3C7,14.9,7.7,15,8.6,15c1.2,0,2.1-0.4,2.7-1.1c0.7-0.7,1.1-1.7,1.2-2.8h3.7c-0.1,1.1-0.3,2.1-0.8,3
			c-0.4,0.9-0.9,1.6-1.6,2.2c-0.7,0.6-1.4,1.1-2.3,1.4c-0.9,0.3-1.9,0.5-3,0.5C7.2,18.2,6,18,5,17.5c-1.1-0.5-2-1.1-2.7-1.9
			c-0.7-0.8-1.3-1.8-1.7-2.9C0.2,11.7,0,10.5,0,9.2c0-1.3,0.2-2.5,0.6-3.6c0.4-1.1,1-2.1,1.7-2.9C3,1.8,3.9,1.2,5,0.7
			C6,0.2,7.2,0,8.6,0c1,0,1.9,0.1,2.7,0.4c0.9,0.3,1.6,0.7,2.3,1.2c0.7,0.5,1.2,1.2,1.7,2c0.4,0.8,0.7,1.7,0.8,2.7h-3.7
			C12.3,5.8,12.2,5.4,11.9,5.1z"
            />
            <path
              d="M21.2,14.8c0.5,0.5,1.3,0.8,2.2,0.8c0.7,0,1.3-0.2,1.8-0.5c0.5-0.3,0.8-0.7,0.9-1.1h3.1
			c-0.5,1.5-1.2,2.6-2.2,3.2c-1,0.7-2.2,1-3.7,1c-1,0-1.9-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2-1.4c-0.6-0.6-1-1.3-1.3-2.1
			c-0.3-0.8-0.5-1.7-0.5-2.7c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.7-1.5,1.3-2.1c0.6-0.6,1.2-1.1,2-1.4c0.8-0.3,1.7-0.5,2.6-0.5
			c1.1,0,2,0.2,2.8,0.6c0.8,0.4,1.5,1,2,1.7c0.5,0.7,0.9,1.5,1.1,2.4c0.2,0.9,0.3,1.8,0.2,2.8h-9.1C20.4,13.5,20.7,14.3,21.2,14.8z
			 M25.1,8.2c-0.4-0.5-1-0.7-1.9-0.7c-0.6,0-1,0.1-1.4,0.3C21.5,8,21.2,8.2,21,8.5c-0.2,0.3-0.4,0.6-0.5,0.9
			c-0.1,0.3-0.1,0.6-0.2,0.8H26C25.8,9.3,25.6,8.6,25.1,8.2z"
            />
            <path
              d="M34.3,5.2v2.3h0c0.2-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.6-0.6,1-0.9c0.4-0.2,0.7-0.4,1.1-0.5
			c0.4-0.1,0.8-0.2,1.3-0.2c0.2,0,0.5,0,0.8,0.1v3.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.7,0c-0.6,0-1.2,0.1-1.6,0.3
			c-0.4,0.2-0.8,0.5-1.1,0.9c-0.3,0.4-0.5,0.8-0.6,1.3c-0.1,0.5-0.2,1-0.2,1.6v5.7H31V5.2H34.3z"
            />
            <path
              d="M48.4,5.2v2.3h-2.5v6.2c0,0.6,0.1,1,0.3,1.2c0.2,0.2,0.6,0.3,1.2,0.3c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5-0.1
			v2.7c-0.3,0-0.6,0.1-1,0.1c-0.4,0-0.7,0-1,0c-0.5,0-1,0-1.5-0.1c-0.5-0.1-0.9-0.2-1.3-0.4c-0.4-0.2-0.7-0.5-0.9-0.9
			c-0.2-0.4-0.3-0.9-0.3-1.5V7.5h-2.1V5.2h2.1V1.4h3.5v3.8H48.4z"
            />
            <path d="M50.4,3.3V0.4h3.5v2.9H50.4z M53.9,5.2v12.6h-3.5V5.2H53.9z" />
            <path
              d="M55.3,7.5V5.2h2.1v-1c0-1.1,0.3-2,1-2.8c0.7-0.7,1.8-1.1,3.2-1.1c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0,0.9,0.1v2.6
			C63,3,62.6,3,62.1,3c-0.5,0-0.8,0.1-1,0.3c-0.2,0.2-0.3,0.6-0.3,1.1v0.8h2.4v2.3h-2.4v10.3h-3.5V7.5H55.3z"
            />
            <path d="M65.6,3.3V0.4H69v2.9H65.6z M69,5.2v12.6h-3.5V5.2H69z" />
            <path
              d="M74.5,14.8c0.5,0.5,1.3,0.8,2.2,0.8c0.7,0,1.3-0.2,1.8-0.5c0.5-0.3,0.8-0.7,0.9-1.1h3
			c-0.5,1.5-1.2,2.6-2.2,3.2c-1,0.7-2.2,1-3.7,1c-1,0-1.9-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2-1.4c-0.6-0.6-1-1.3-1.3-2.1
			c-0.3-0.8-0.5-1.7-0.5-2.7c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.7-1.5,1.3-2.1c0.6-0.6,1.2-1.1,2-1.4c0.8-0.3,1.7-0.5,2.6-0.5
			c1.1,0,2,0.2,2.8,0.6c0.8,0.4,1.5,1,2,1.7c0.5,0.7,0.9,1.5,1.1,2.4c0.2,0.9,0.3,1.8,0.2,2.8h-9.1C73.7,13.5,74,14.3,74.5,14.8z
			 M78.4,8.2c-0.4-0.5-1-0.7-1.9-0.7c-0.6,0-1,0.1-1.4,0.3c-0.4,0.2-0.7,0.4-0.9,0.7C74,8.7,73.9,9,73.8,9.3
			c-0.1,0.3-0.1,0.6-0.2,0.8h5.6C79.1,9.3,78.8,8.6,78.4,8.2z"
            />
            <path
              d="M93.3,16.2c-0.4,0.7-0.9,1.2-1.6,1.5c-0.7,0.3-1.4,0.5-2.2,0.5c-0.9,0-1.8-0.2-2.5-0.5
			c-0.7-0.4-1.3-0.9-1.8-1.5c-0.5-0.6-0.8-1.3-1-2.2c-0.2-0.8-0.4-1.7-0.4-2.5c0-0.8,0.1-1.7,0.4-2.5c0.2-0.8,0.6-1.5,1-2.1
			c0.5-0.6,1-1.1,1.7-1.5c0.7-0.4,1.5-0.5,2.4-0.5c0.7,0,1.5,0.2,2.1,0.5c0.7,0.3,1.2,0.8,1.6,1.4h0V0.4h3.5v17.4h-3.3L93.3,16.2
			L93.3,16.2z M93.1,10c-0.1-0.5-0.3-0.9-0.5-1.3c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.8-0.3-1.4-0.3s-1,0.1-1.4,0.3
			c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.4,0.8-0.5,1.3c-0.1,0.5-0.2,1-0.2,1.5c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.3,0.9,0.5,1.3
			c0.3,0.4,0.6,0.7,1,0.9c0.4,0.2,0.8,0.4,1.4,0.4c0.6,0,1-0.1,1.4-0.3c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.4-0.8,0.5-1.3
			c0.1-0.5,0.1-1,0.1-1.5S93.2,10.5,93.1,10z"
            />
          </g>
          <g>
            <path
              d="M8.7,151.8c-0.2-1.2-1.3-2.1-2.7-2.1c-2.4,0-3.3,2.1-3.3,4.2c0,2,0.9,4.1,3.3,4.1c1.6,0,2.6-1.1,2.8-2.7h2.6
			c-0.3,3.1-2.4,5-5.4,5c-3.8,0-6-2.8-6-6.4c0-3.6,2.2-6.5,6-6.5c2.7,0,5,1.6,5.3,4.4H8.7z"
            />
            <path
              d="M17.1,150.8c2.8,0,4.6,1.9,4.6,4.7c0,2.8-1.8,4.7-4.6,4.7c-2.8,0-4.6-1.9-4.6-4.7
			C12.5,152.6,14.3,150.8,17.1,150.8z M17.1,158.3c1.7,0,2.2-1.4,2.2-2.8c0-1.4-0.5-2.9-2.2-2.9c-1.6,0-2.1,1.4-2.1,2.9
			C14.9,156.9,15.4,158.3,17.1,158.3z"
            />
            <path
              d="M23.2,151h2.3v1.6h0c0.4-1.1,1.6-1.9,2.8-1.9c0.2,0,0.4,0,0.5,0.1v2.3c-0.2-0.1-0.6-0.1-0.9-0.1
			c-1.8,0-2.4,1.3-2.4,2.9v4h-2.4V151z"
            />
            <path
              d="M29.8,151h2.3v1.1h0c0.6-0.9,1.5-1.4,2.6-1.4c2.8,0,4,2.2,4,4.8c0,2.4-1.3,4.6-3.9,4.6
			c-1.1,0-2.1-0.5-2.7-1.3h0v4.2h-2.4V151z M36.4,155.5c0-1.4-0.6-2.9-2.1-2.9c-1.6,0-2.1,1.4-2.1,2.9c0,1.4,0.5,2.8,2.1,2.8
			C35.9,158.3,36.4,156.9,36.4,155.5z"
            />
            <path
              d="M44.2,150.8c2.8,0,4.6,1.9,4.6,4.7c0,2.8-1.8,4.7-4.6,4.7c-2.8,0-4.6-1.9-4.6-4.7
			C39.6,152.6,41.4,150.8,44.2,150.8z M44.2,158.3c1.7,0,2.2-1.4,2.2-2.8c0-1.4-0.5-2.9-2.2-2.9c-1.6,0-2.1,1.4-2.1,2.9
			C42.1,156.9,42.6,158.3,44.2,158.3z"
            />
            <path
              d="M50.3,151h2.3v1.6h0c0.4-1.1,1.6-1.9,2.8-1.9c0.2,0,0.4,0,0.5,0.1v2.3c-0.2-0.1-0.6-0.1-0.9-0.1
			c-1.8,0-2.4,1.3-2.4,2.9v4h-2.4V151z"
            />
            <path
              d="M56.8,153.8c0.1-2.3,2.2-3,4.2-3c1.8,0,3.9,0.4,3.9,2.5v4.6c0,0.8,0.1,1.6,0.3,2h-2.5
			c-0.1-0.3-0.2-0.6-0.2-0.9c-0.8,0.8-1.9,1.1-3,1.1c-1.7,0-3-0.8-3-2.7c0-2,1.5-2.5,3-2.7c1.5-0.2,2.9-0.2,2.9-1.2
			c0-1-0.7-1.2-1.6-1.2c-0.9,0-1.5,0.4-1.6,1.3H56.8z M62.4,155.6c-0.4,0.4-1.3,0.4-2,0.5c-0.8,0.2-1.4,0.4-1.4,1.3
			c0,0.9,0.7,1.1,1.5,1.1c1.9,0,2-1.5,2-2V155.6z"
            />
            <path
              d="M69.8,151h1.8v1.6h-1.8v4.4c0,0.8,0.2,1,1,1c0.3,0,0.5,0,0.8-0.1v1.9c-0.4,0.1-0.9,0.1-1.4,0.1
			c-1.5,0-2.8-0.3-2.8-2.1v-5.2h-1.5V151h1.5v-2.7h2.4V151z"
            />
            <path d="M75.9,149.6h-2.4v-2h2.4V149.6z M73.4,151h2.4v8.9h-2.4V151z" />
            <path
              d="M82.1,150.8c2.8,0,4.6,1.9,4.6,4.7c0,2.8-1.8,4.7-4.6,4.7c-2.8,0-4.6-1.9-4.6-4.7
			C77.5,152.6,79.3,150.8,82.1,150.8z M82.1,158.3c1.7,0,2.2-1.4,2.2-2.8c0-1.4-0.5-2.9-2.2-2.9c-1.6,0-2.1,1.4-2.1,2.9
			C79.9,156.9,80.4,158.3,82.1,158.3z"
            />
            <path
              d="M88.3,151h2.3v1.2h0.1c0.6-1,1.7-1.5,2.7-1.5c2.6,0,3.2,1.5,3.2,3.7v5.5h-2.4v-5c0-1.5-0.4-2.2-1.6-2.2
			c-1.3,0-1.9,0.7-1.9,2.5v4.7h-2.4V151z"
            />
          </g>
          <g></g>
        </g>
      </svg>
    </>
  )
}

export default Bcorps
